@use '@angular/cdk';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@vsolv/vectors-ui/theme';
@import '@vsolv/vectors-ui/scrollbar';

@import '@angular/cdk/overlay-prebuilt.css';
@include cdk.text-field-autosize();

//optional class to hide scrollbars
@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .h-screen {
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
    z-index: 999;
    position: fixed;
    transform: translate3d(0, 0, 0);
    -webkit-overflow-scrolling: touch;
  }

  .max-h-screen {
    @supports (-webkit-touch-callout: none) {
      max-height: -webkit-fill-available;
    }
    z-index: 999;
    position: fixed;
    transform: translate3d(0, 0, 0);
    -webkit-overflow-scrolling: touch;
  }
}
